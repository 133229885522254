<template>
    <div class="mt-6">
        <template v-if="documentExpired">
            <h2 class="title is-2">
                {{ $t('message.greeting.document_expired') }}
            </h2>

            <p class="mb-6">
                <i>{{ $t('message.greeting.document_expired_instructions') }}</i>
            </p>
        </template>
        <template v-else>
            <h2 class="title is-2">
                {{ $t('message.greeting.new_contract') }}
            </h2>
        </template>

        <div class="document-form mb-4">
            <div class="columns">
                <div class="column is-6">
                    <div class="field">
                        <label class="label">
                            {{ $t('message.fields.financial.bsn') }}

                            <span class="is_blue">
                                *
                            </span>
                            
                        </label>
                        
                        <div class="mb-3">
                            {{ $t('message.fields.financial.bsn_description') }}
                        </div>

                        <div class="control pr-2">
                            <input
                                v-model="financial.bsn.value"
                                class="input"
                                :class="{ 'is-danger': required('document_number') }"
                                type="text"
                                :placeholder="`${$t('message.fields.financial.bsn')}...`"
                            />
                        </div>
                    </div>
                </div>
                <div class="column is-6">
                    <div class="field">
                        <label class="label"
                            >{{ $t('message.fields.financial.loonheffingskorting') }} <span class="is_blue">*</span></label
                        >
                        <div class="control">
                            <label class="radio">
                            <input
                                v-model="financial.loonheffingskorting"
                                :class="{ 'is-danger': required('loonheffingskorting') }"
                                type="radio"
                                :value="1"
                            />
                            {{ $t('message.options.yes') }}
                            </label>
                            <label class="radio">
                            <input
                                v-model="financial.loonheffingskorting"
                                :class="{ 'is-danger': required('loonheffingskorting') }"
                                type="radio"
                                :value="0"
                            />
                                {{ $t('message.options.no') }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <template v-if="documentExpired">
                <div class="columns">
                    <div class="column">
                        <div class="field">
                            <label class="label">
                                {{ $t('message.fields.financial.type') }}
                                <span class="is_blue">
                                    *
                                </span>
                            </label>

                            <div class="control">
                                <label class="radio mr_responsive">
                                    <input
                                        v-model="financial.document_type"
                                        value="Nederlands Paspoort"
                                        type="radio"
                                        name="document_type"
                                        :class="{ 'is-danger': required('document_type') }"
                                    />

                                    <i class="m-2" :class="{ is_red: required('document_type') }">
                                        {{ $t('message.fields.financial.nl_national') }}
                                    </i>
                                </label>

                                <label class="radio mr_responsive">
                                    <input
                                        v-model="financial.document_type"
                                        value="Nationaal Paspoort"
                                        type="radio"
                                        name="document_type"
                                        :class="{ 'is-danger': required('document_type') }"
                                    />

                                    <i class="m-2" :class="{ is_red: required('document_type') }">
                                        {{ $t('message.fields.financial.national') }}
                                    </i>
                                </label>

                                <label class="radio mr_responsive">
                                    <input
                                        v-model="financial.document_type"
                                        value="NL id-kaart"
                                        type="radio"
                                        name="document_type"
                                        :class="{ 'is-danger': required('document_type') }"
                                    />

                                    <i class="m-2" :class="{ is_red: required('document_type') }">
                                        {{ $t('message.fields.financial.nl') }}
                                    </i>
                                </label>

                                <label class="radio mr_responsive">
                                    <input
                                        v-model="financial.document_type"
                                        value="EU id-kaart"
                                        type="radio"
                                        name="document_type"
                                        :class="{ 'is-danger': required('document_type') }"
                                    />

                                    <i class="m-2" :class="{ is_red: required('document_type') }">
                                        {{ $t('message.fields.financial.eu') }}
                                    </i>
                                </label>

                                <label
                                    class="radio mr_responsive"
                                    :class="{ 'is-danger': required('document_type') }"
                                >
                                    <input
                                        v-model="financial.document_type"
                                        value="Vreemdelingen document"
                                        type="radio"
                                        name="document_type"
                                        :class="{ 'is-danger': required('document_type') }"
                                    />

                                    <i class="m-2" :class="{ is_red: required('document_type') }">
                                        {{ $t('message.fields.financial.alien') }}
                                    </i>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="columns">
                    <div class="column">
                        <div class="field">
                            <label class="label">
                                {{ $t('message.fields.financial.document_number') }}

                                <span class="is_blue">
                                    *
                                </span>
                            </label>

                            <div class="control pr-2">
                                <input
                                    v-model="financial.document_number.value"
                                    class="input"
                                    :class="{ 'is-danger': required('document_number') }"
                                    type="text"
                                    :placeholder="`${$t('message.fields.financial.document_number')}...`"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="column">
                        <div class="field">
                            <label class="label">
                                {{ $t('message.fields.financial.expiration_date') }}

                                <span class="is_blue">
                                    *
                                </span>
                            </label>

                            <div class="control pr-2">
                                <b-field>
                                    <b-datepicker
                                        v-model="financial.document_expiration_date.value"
                                        :show-week-number="false"
                                        :locale="undefined"
                                        :class="{ 'is-danger': required('document_expiration_date') }"
                                        placeholder="dd/mm/jjjj"
                                        trap-focus
                                    >
                                    </b-datepicker>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        <label class="label">
                            {{ $t('message.fields.financial.front') }}

                            <span class="is_blue">
                                *
                            </span>
                        </label>

                        <file-upload
                            :value="financial.document_front_hash" 
                            customUrl="https://werken-alac.nl/api/contract/media/image" 
                            :token="token"
                            :preventDeleteFromServer="true"
                            :isMedia="true"
                        />
                    </div>

                    <div class="column">
                        <label class="label">
                            {{ $t('message.fields.financial.back') }}

                            <span class="is_blue">
                                *
                            </span>
                        </label>

                        <file-upload
                            :value="financial.document_back_hash" 
                            customUrl="https://werken-alac.nl/api/contract/media/image" 
                            :token="token"
                            :preventDeleteFromServer="true"
                            :isMedia="true"
                        />
                    </div>
                </div>

                <div class="columns" v-if="requireBsnDocument">
                    <div class="column is-6">
                        <label class="label">
                            {{ $t('message.fields.financial.bsn_document') }}

                            <span class="is_blue">
                                *
                            </span>
                        </label>

                        <file-upload
                            :value="financial.bsn_document_hash" 
                            customUrl="https://werken-alac.nl/api/contract/media/image" 
                            :token="token"
                            :preventDeleteFromServer="true"
                            :isMedia="true"
                        />
                    </div>
                </div>
            </template>
        </div>

        <button class="button is-info mb-4" @click="submit" :disabled="!canSubmit">
            <span>{{ $t('message.controls.next') }}</span>

            <span class="icon">
                <i class="fas fa-arrow-right"></i>
            </span>
        </button>
    </div>
</template>

<script>
import FileUpload from '../components/FileUpload.vue';
import Api from "../api/api";
let api = new Api();
import moment from 'moment';


export default {
    components: {
        FileUpload
    },

    props: {
        token: {
            type: String,
        },
        contract_id: {
            type: Number
        },
        nationality: {
            type: String
        },
        documentExpired: {
            type: Boolean
        }
    },

    data() {
        return {
            financial: {
                document_type: "",
                bsn: {
                    value: "",
                    valid: false,
                    id: ""
                },
                document_number: {
                    value: "",
                    valid: false,
                    id: ""
                },
                document_expiration_date: {
                    value: null,
                    valid: false,
                    id: ""
                },
                document_front_hash: {
                    value: "",
                    valid: false,
                    id: ""
                },
                document_back_hash: {
                    value: "",
                    valid: false,
                    id: ""
                },
                bsn_document_hash: {
                    value: "",
                    valid: false,
                    id: ""
                },
                loonheffingskorting: ""
            },
        }
    },
    computed: {
        canSubmit() {
            if (!this.documentExpired) {
                return this.financial.bsn.value && this.financial.loonheffingskorting !== ''
            }

            if (this.requireBsnDocument) {
                return this.financial.document_expiration_date.value && 
                    this.financial.document_number.value &&
                    this.financial.document_type &&
                    this.financial.document_front_hash.value &&
                    this.financial.document_back_hash.value && 
                    this.financial.bsn_document_hash.value &&
                    this.financial.bsn.value &&
                    this.financial.loonheffingskorting !== ''
            }

            return this.financial.document_expiration_date.value && 
                this.financial.document_number.value &&
                this.financial.document_type &&
                this.financial.document_front_hash.value &&
                this.financial.document_back_hash.value &&
                this.financial.bsn.value &&
                this.financial.loonheffingskorting !== ''
        },
        requireBsnDocument() {
            return (
                this.nationality === '' &&
                (
                    this.financial.document_type === 'Nationaal Paspoort' ||
                    this.financial.document_type === 'EU id-kaart' ||
                    this.financial.document_type === 'Vreemdelingen document'
                )
            ) ||
            (
                this.nationality !== '' &&
                this.nationality !== 'NL'
            )
        }
    },
    methods: {
        required: function() {
            return false;
        },
        submit() {
            let formattedDate = null;
            if(this.financial.document_expiration_date.value) {
                formattedDate = moment(
                    this.financial.document_expiration_date.value
                ).format("YYYY-MM-DD");
            }

            let documentType = this.financial.document_type
            if (documentType === 'Nederlands Paspoort') {
                documentType = 'Nationaal Paspoort'
            }

            let data = {
                contract_id: this.contract_id,
                document_expiration_date: formattedDate,
                document_number: this.financial.document_number.value,
                document_type: documentType,
                document_front_hash: this.financial.document_front_hash.value,
                document_back_hash: this.financial.document_back_hash.value,
                bsn: this.financial.bsn.value,
                loonheffingskorting: this.financial.loonheffingskorting
            }


            if (this.requireBsnDocument) {
                data.bsn_document_hash = this.financial.bsn_document_hash.value
            }

            if (!this.documentExpired) {
                data = {
                    contract_id: this.contract_id,
                    bsn: this.financial.bsn.value,
                    loonheffingskorting: this.financial.loonheffingskorting
                }
            }

            api
                .patch(`/employment/contract`, data)
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.$emit('refresh')
                })
        }
    }
}
</script>
